*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
ol, ul,li {
    list-style: none;
  }
a {
    text-decoration: none;
    color: inherit;
  }
.scroll-list{
    width: 100%;
    /* height: 704px; */
    /* background-color: pink; */
    img{
        width: 100%;
        height: 100%;
    }
}
.swiper{
    width: 100%;
    height:100%;
}
.core-business{
   width: 100%;
   /* height: 824px; */
   /* margin-bottom: 30px; */
   padding-bottom: 30px;
   background-color:#f8f8f8;
}
.core-business img{
    display: block;
    margin: 0 auto;

}
.core-business .core-list{
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    gap: 20px;
    justify-content: space-between;
}
.core-business .core-list .core-li{
    flex: 1;
    /* width: 376px; */
    /* height: 503px; */
    background-color: #fff;
    /* border: 1px solid #e5e5e5; */

}
.core-business .core-list .core-li img{
    width: 100%;
    /* height: 242px; */
}
.core-business .core-li-content{
    height: 162px;
    padding: 0px 15px;
    /* margin-bottom: 66px; */
}
.core-business .core-li-content .core-li-title{
    font-size: 18px;
    font-weight: 400;
    color: #010101;
    margin-bottom: 22px;
    margin-top: 62px;
}
.core-business .core-li-content .core-li-des{
    font-size: 15px;
    font-weight: 300;
    color: #9c9c9c;
}
.core-business .core-li-bottom{
    margin: 0px 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    border-top: 1px solid #e5e5e5;
    font-size: 12px;
    color: #adadad;
}

.ca .img{
    display: block;
    margin: 0 auto;
}
.ca .ca-list{
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.ca .ca-list .ca-li{
    flex: 0 0 33.333%;
   
}
.ca .ca-list .ca-li img{
    width: 100%;
    height: 100%;
}

.zl{
    width: 1200px;
    margin: 0 auto;
}
.zl img{
    width: 936px;
    height: 843px;
    display: block;
    margin: 0 auto;

}
.join{
    width: 100%;
    position: relative;
    .j_title_main{
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        text-align: center;
        color: #fff;
        .title1{
            font-size: 34px;
            font-weight: 700;
        }
        .title2{
            font-size: 24px;
            /* font-weight: 700; */
        }
        .fenge{
            margin-top: 6px;
        }
        .title3{
            margin-top: 6px;
        }
    }
    /* background-image: url('../images/join-bg.jpg'); */
}
.join .bg{
    width: 100%;
    display: block;
    /* height: 100%; */
}
.join .title{
    position: absolute;
    top:86px;
    left:50%;
    transform: translate(-50%);
    z-index: 2;
    width:19%;
}
.join .content{
    position: absolute;
    left:50%;
    bottom: 54px;
    transform: translate(-50%);
    z-index: 2;
    width:45%;
    /* height:; */
}
.b_title{
    text-align: center;
    margin: 50px 0;
    .title{
        font-size: 34px;
        color: #c7c7c7;
        font-weight: 700;
    }
    .subtitle{
        font-size: 24px;
    }
    .f{
        margin-top: 6px;
    }
    .ssubtitle{
        margin-top: 6px;
    }
    /* margin: 0 auto; */
}
.zlhz{
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    .item{
        flex: 0 0 33.333%;
        padding: 0 10px;
        box-sizing: border-box;
        margin-bottom: 80px;
        text-align: center;
        .img{
            margin-bottom: 4px;
        }
        /* img{
            width:60px;
            height: 100%;
        } */
    }
}