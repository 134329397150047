* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ol, ul, li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.scroll-list {
  width: 100%;

  & img {
    width: 100%;
    height: 100%;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.core-business {
  background-color: #f8f8f8;
  width: 100%;
  padding-bottom: 30px;
}

.core-business img {
  margin: 0 auto;
  display: block;
}

.core-business .core-list {
  justify-content: space-between;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}

.core-business .core-list .core-li {
  background-color: #fff;
  flex: 1;
}

.core-business .core-list .core-li img {
  width: 100%;
}

.core-business .core-li-content {
  height: 162px;
  padding: 0 15px;
}

.core-business .core-li-content .core-li-title {
  color: #010101;
  margin-top: 62px;
  margin-bottom: 22px;
  font-size: 18px;
  font-weight: 400;
}

.core-business .core-li-content .core-li-des {
  color: #9c9c9c;
  font-size: 15px;
  font-weight: 300;
}

.core-business .core-li-bottom {
  color: #adadad;
  border-top: 1px solid #e5e5e5;
  align-items: center;
  margin: 0 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  display: flex;
}

.ca .img {
  margin: 0 auto;
  display: block;
}

.ca .ca-list {
  box-sizing: border-box;
  flex-wrap: wrap;
  display: flex;
}

.ca .ca-list .ca-li {
  flex: 0 0 33.333%;
}

.ca .ca-list .ca-li img {
  width: 100%;
  height: 100%;
}

.zl {
  width: 1200px;
  margin: 0 auto;
}

.zl img {
  width: 936px;
  height: 843px;
  margin: 0 auto;
  display: block;
}

.join {
  width: 100%;
  position: relative;

  & .j_title_main {
    text-align: center;
    color: #fff;
    width: 100%;
    position: absolute;
    top: 40px;
    left: 0;

    & .title1 {
      font-size: 34px;
      font-weight: 700;
    }

    & .title2 {
      font-size: 24px;
    }

    & .fenge, & .title3 {
      margin-top: 6px;
    }
  }
}

.join .bg {
  width: 100%;
  display: block;
}

.join .title {
  z-index: 2;
  width: 19%;
  position: absolute;
  top: 86px;
  left: 50%;
  transform: translate(-50%);
}

.join .content {
  z-index: 2;
  width: 45%;
  position: absolute;
  bottom: 54px;
  left: 50%;
  transform: translate(-50%);
}

.b_title {
  text-align: center;
  margin: 50px 0;

  & .title {
    color: #c7c7c7;
    font-size: 34px;
    font-weight: 700;
  }

  & .subtitle {
    font-size: 24px;
  }

  & .f, & .ssubtitle {
    margin-top: 6px;
  }
}

.zlhz {
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;

  & .item {
    box-sizing: border-box;
    text-align: center;
    flex: 0 0 33.333%;
    margin-bottom: 80px;
    padding: 0 10px;

    & .img {
      margin-bottom: 4px;
    }
  }
}
/*# sourceMappingURL=index.9fadc41b.css.map */
